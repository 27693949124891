import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";

import { Header } from "../../components/base/Header";
import { CustomSpinner } from "../../components/CustomSpinner";
import { useCollectionWithQuestionArray } from "../../hooks/useCollectionWithQuestionArray";
import { useLogging } from "../../hooks/useLogging";
import { Footer } from "../../components/base/Footer";
import { BackBottom } from "../../components/parts/buttons/BackButton";
import { MainArea } from "../../components/base/MainArea";
import { useCommon } from "../../hooks/useCommon";

export const CollectionStartPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const collectionId = parseInt(query.get("collection_id"));
  const courseId = parseInt(query.get("course_id"));
  const { userId } = useCommon();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const {
    getCollectionWithQuestionArray,
    collectionWithQuestionArray,
    collectionWithQuestionArrayLoading,
  } = useCollectionWithQuestionArray();
  const { getCollectionHistoryId, collectionHistoryId, loggingLoading } =
    useLogging();

  useEffect(() => {
    const fetchData = async () => {
      await getCollectionWithQuestionArray(collectionId, courseId);
      await getCollectionHistoryId(userId, collectionId, courseId);
    };
    fetchData();
  }, [getCollectionWithQuestionArray, getCollectionHistoryId, collectionId, courseId, userId]);

  useEffect(() => {
    if (
      !loggingLoading &&
      !collectionWithQuestionArrayLoading &&
      collectionWithQuestionArray.questions
    ) {
      setShouldRedirect(true);
    }
  }, [
    loggingLoading,
    collectionWithQuestionArrayLoading,
    collectionWithQuestionArray,
  ]);

  if (loggingLoading || collectionWithQuestionArrayLoading) {
    return (
        <div className="bg-light min-vh-100">
          <Header />
          <div className="main">
            <MainArea>
              <CustomSpinner />
            </MainArea>
            <div className="container">
              <div className="mb-4 mt-2">
                <BackBottom backRoute={`/course?course_id=${courseId}`} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
    );
  }

  if (shouldRedirect && collectionWithQuestionArray.questions) {
    return (
        <Navigate
            to="/question"
            state={{
              courseId: courseId,
              collectionId: collectionId,
              collectionName: collectionWithQuestionArray["collectionName"],
              description: collectionWithQuestionArray["description"],
              currentQuestionNumber: 0,
              answerArray: [],
              questionArray: collectionWithQuestionArray.questions,
              collectionHistoryId: collectionHistoryId,
            }}
            replace
        />
    );
  }

  return (
      <div className="bg-light min-vh-100">
        <Header />
        <div className="main">
          <MainArea>
            <CustomSpinner />
          </MainArea>
          <div className="container">
            <div className="mb-4 mt-2">
              <BackBottom backRoute={`/course?course_id=${courseId}`} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
  );
};
