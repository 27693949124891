import React from "react";
import { PostLog } from "../../PostLog";
import { SlideStandard } from "./SlideStandard";
import { SlideStandardImage } from "./SlideStandardImage";
import { SlideFullVideo } from "./SlideFullVideo";
import { SlideTitleOnly } from "./SlideTitleOnly";
import { SlideFullImage } from "./SlideFullImage";
import { DateTime } from "luxon";
import { s3Url } from "../../../configs/common";
import { SubmitButton } from "../../parts/buttons/SubmitButton";
import { CollectionTitle } from "../../parts/questions/CollectionTitle";
import { ProgressBar } from "../../parts/progresses/ProgressBar";
import { SlideStandardVideo } from "./SlideStandardVideo";

export const SlideMain = (props) => {
  const {
    courseId,
    collectionId,
    collectionName,
    description,
    questionArray,
    questionsNumber,
    currentQuestionNumber,
    answerArray,
    collectionHistoryId,
    userId,
  } = props;

  answerArray[currentQuestionNumber] = null;

  const startAt = DateTime.now();
  const correctAnswer = 1;
  const correctAnswerStatus = 1;

  const selectedSlide = (question) => {
    if (question.slideType === "standard") {
      return (
        <SlideStandard
          title={question.title}
          subtitle={question.subtitle}
          sentence={question.sentence}
          audioFileUrl={
            question.questionAudioPath !== null
              ? s3Url + "/" + question.questionAudioPath
              : null
          }
        />
      );
    }
    if (question.slideType === "standard_image") {
      return (
        <SlideStandardImage
          title={question.title}
          subtitle={question.subtitle}
          sentence={question.sentence}
          imageFileUrl={s3Url + "/" + question.questionImagePath}
          audioFileUrl={
            question.questionAudioPath !== undefined
              ? s3Url + "/" + question.questionAudioPath
              : undefined
          }
        />
      );
    }
    if (question.slideType === "standard_video") {
      return (
        <SlideStandardVideo
          title={question.title}
          subtitle={question.subtitle}
          sentence={question.sentence}
          videoFileUrl={s3Url + "/" + question.questionVideoPath}
        />
      );
    }
    if (question.slideType === "title_only") {
      return (
        <SlideTitleOnly title={question.title} subtitle={question.subtitle} />
      );
    }
    if (question.slideType === "image_full") {
      return (
        <SlideFullImage
          imageFileUrl={s3Url + "/" + question.questionImagePath}
        />
      );
    }
    if (question.slideType === "web_full") {
      return (
        <SlideTitleOnly title={question.title} subtitle={question.subtitle} />
      );
    }
    if (question.slideType === "video_full") {
      return (
        <SlideFullVideo
          videoFileUrl={s3Url + "/" + question.questionVideoPath}
        />
      );
    }
    return (
      <p>
        {"スライドタイプが不正です"}。Type: {question.slideType}
      </p>
    );
  };

  return (
    <>
      <ProgressBar
        count={questionsNumber.questionNumber}
        totalCount={questionsNumber.questionsLength}
      />
      <CollectionTitle>{collectionName}</CollectionTitle>
      {/*問題集の説明は一時的に削除*/}
      {/*<QuestionDescription>{description}</QuestionDescription>*/}
      {selectedSlide(questionArray[currentQuestionNumber])}
      <div className="mt-3 d-flex justify-content-center px-3 pb-3">
        <SubmitButton
          route={"/answer"}
          state={{
            courseId: courseId,
            collectionId: collectionId,
            collectionName: collectionName,
            description: description,
            currentQuestionNumber: currentQuestionNumber,
            answerArray: answerArray,
            questionArray: questionArray,
            collectionHistoryId: collectionHistoryId,
          }}
          onClick={() =>
            PostLog({
              logType: "question",
              userId: userId,
              collectionId: collectionId,
              answer: answerArray[currentQuestionNumber],
              questionId: questionArray[currentQuestionNumber]["_id"],
              collectionHistoryId: collectionHistoryId,
              startAt: startAt.toISO(),
              correctAnswerStatus: correctAnswerStatus,
              correctAnswer: correctAnswer,
            })
          }
          label={"受講完了"}
        />
      </div>
    </>
  );
};
