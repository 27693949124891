import React, { useEffect } from "react";
import { Header } from "../../components/base/Header";
import { PutCollectionLog } from "../../components/PutCollectionLog";
import { getAnswerTypeJpn } from "../../functions/getAnswerTypeJpn";
import { convertNewLine } from "../../functions/convertNewLine";
import { Footer } from "../../components/base/Footer";
import { MainArea } from "../../components/base/MainArea";
import { CollectionTitle } from "../../components/parts/questions/CollectionTitle";
import { SubmitButton } from "../../components/parts/buttons/SubmitButton";
import { CorrectIncorrectLabel } from "../../components/parts/labels/CorrectIncorrectLabel";
import { useCommon } from "../../hooks/useCommon";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const CollectionResultPage = () => {
  const location = useLocation();
  const {
    courseId,
    collectionId,
    collectionName,
    // description,
    // currentQuestionNumber,
    questionArray,
    answerArray,
    collectionHistoryId,
  } = location.state;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  // const courseId = useRecoilValue(questionProcessingCourseId);
  // const collection = useRecoilValue(
  //   questionProcessingCollectionWithQuestionArray,
  // );
  // const collectionId = collection.collectionId;
  // const collectionName = collection.collectionName;
  // const questionArray = collection.questions;
  // const answerArray = useRecoilValue(questionProcessingAnswerArray);
  // const collectionHistoryId = useRecoilValue(
  //   questionProcessingCollectionHistoryId,
  // );

  // const resetCollectionWithQuestionArray = useResetRecoilState(
  //   questionProcessingCollectionWithQuestionArray,
  // );
  // const resetCollectionHistoryId = useResetRecoilState(
  //   questionProcessingCollectionHistoryId,
  // );
  // const resetAnswerArray = useResetRecoilState(questionProcessingAnswerArray);
  // const resetCollectionId = useResetRecoilState(questionProcessingCollectionId);

  // const resetCurrentQuestionNumber = useResetRecoilState(
  //   questionProcessingCurrentQuestionNumber,
  // );

  const { userId } = useCommon();

  let correctAnswerCount = 0;
  let slideSlideCount = 0;

  const compareAnswer = (currentAnswer, answerArray) => {
    if (currentAnswer.length > 1) {
      for (let i = 0; i < currentAnswer.length; i++) {
        if (currentAnswer[i] !== answerArray[i]) {
          return false;
        }
      }
      return true;
    } else {
      return currentAnswer[0] === answerArray;
    }
  };

  let slideOnlyFlg = true;
  questionArray.map((question, index) => {
    // 対象がスライドの場合はスキップ
    if (question.screenType.search(/slide/) !== -1) {
      slideSlideCount = slideSlideCount + 1;
      return false;
    }
    // スライド以外の場合は回答を比較
    if (
      compareAnswer(question.answer, answerArray[index]) &&
      question.screenType.search(/slide/) === -1
    ) {
      correctAnswerCount = correctAnswerCount + 1;
    }
    slideOnlyFlg = false;
    return false;
  });
  const score = Math.round(
    100 * (correctAnswerCount / (questionArray.length - slideSlideCount)),
  );
  let status = "0";
  if (slideOnlyFlg) {
    status = "1";
  } else {
    status = score >= 100 ? "1" : "0";
  }
  useEffect(() => {
    (() =>
      PutCollectionLog({
        userId: userId,
        courseId: courseId,
        collectionId: collectionId,
        collectionHistoryId: collectionHistoryId,
        score: isNaN(score) ? "0" : score,
        status: status,
      }))();
  }, [collectionHistoryId, collectionId, courseId, score, status, userId]);

  // const resetQuestionProcessing = () => {
  //   resetCollectionWithQuestionArray();
  //   resetAnswerArray();
  //   resetCollectionHistoryId();
  //   resetCollectionId();
  //   resetCurrentQuestionNumber();
  // };

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="main">
        <MainArea>
          <CollectionTitle>{collectionName}</CollectionTitle>
          {slideOnlyFlg ? (
            <h2 className="text-center">
              動画学習完了
              <br />
              お疲れ様でした！
            </h2>
          ) : (
            <div className="mb-3">
              <table className="result-table">
                <thead>
                  <tr>
                    <th scope="col">{"合否"}</th>
                    <th scope="col">{"問題名"}</th>
                    <th scope="col">{"問題タイプ"}</th>
                  </tr>
                </thead>
                <tbody>
                  {questionArray.map((question, index) => {
                    if (question.screenType.search(/slide/) === -1) {
                      if (question.answerType === "speaking") {
                        return (
                          <tr key={index}>
                            <td className="d-flex justify-content-center">
                              <CorrectIncorrectLabel
                                correctFlg={answerArray[index].confidence > 0.9}
                              />
                            </td>
                            <td>{convertNewLine(question.sentence)}</td>
                            <td>{question.answer_type}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={index}>
                            <td className="h-100">
                              <div className="d-flex justify-content-center">
                                <CorrectIncorrectLabel
                                  correctFlg={compareAnswer(
                                    question.answer,
                                    answerArray[index],
                                  )}
                                />
                              </div>
                            </td>
                            <td>{convertNewLine(question.sentence)}</td>
                            <td>{getAnswerTypeJpn(question.answerType)}</td>
                          </tr>
                        );
                      }
                    }
                    return false;
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div className="d-flex justify-content-center px-3 pb-3">
            <SubmitButton
              route={"/course?course_id=" + courseId}
              label="動画一覧に戻る"
              // onClick={() => resetQuestionProcessing()}
            />
          </div>
        </MainArea>
      </div>
      <Footer />
    </div>
  );
};
