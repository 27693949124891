import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Header } from "../../components/base/Header";
import { Footer } from "../../components/base/Footer";
import { MainArea } from "../../components/base/MainArea";
import { ChoiceQuestion } from "../../components/question/choice/ChoiceQuestion";
import { CustomSpinner } from "../../components/CustomSpinner";
import { SlideMain } from "../../components/question/slide/SlideMain";
import { DictationQuestion } from "../../components/question/dictation/DictationQuestion";
import { QuestionBackButton } from "../../components/parts/buttons/QuestionBackButton";
import { useCommon } from "../../hooks/useCommon";
import ReactGA from "react-ga4";
import { useUsers } from "../../hooks/useUsers";

export const QuestionPage = () => {
  const { userId } = useCommon();
  const location = useLocation();
  const {
    courseId,
    collectionId,
    collectionName,
    description,
    currentQuestionNumber,
    questionArray,
    answerArray,
    collectionHistoryId,
  } = location.state;

  let navigate = useNavigate();

  const { getUsers, users, usersLoading } = useUsers();
  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const questionsNumber = {
    questionsLength: questionArray.length > 0 ? questionArray.length : 0,
    questionNumber: currentQuestionNumber,
  };

  const selectedQA = (question) => {
    if (question.answerType === "choice") {
      return (
        <ChoiceQuestion
          question={question}
          questionsNumber={questionsNumber}
          userId={userId}
        />
      );
    }
    if (question.answerType === "word" || question.answerType === "sentence") {
      return (
        <DictationQuestion
          question={question}
          questionsNumber={questionsNumber}
          userId={userId}
        />
      );
    }
    if (question.screenType === "slide") {
      return (
        <SlideMain
          courseId={courseId}
          collectionId={collectionId}
          collectionName={collectionName}
          description={description}
          questionArray={questionArray}
          questionsNumber={questionsNumber}
          currentQuestionNumber={currentQuestionNumber}
          answerArray={answerArray}
          collectionHistoryId={collectionHistoryId}
          question={question}
          userId={userId}
        />
      );
    }
    return (
      <p>
        {"問題タイプが不正です。"}Type: {question.slide_type}
      </p>
    );
  };

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="main">
        <MainArea>
          {questionArray.length === 0 ? (
            <CustomSpinner />
          ) : (
            selectedQA(questionArray[currentQuestionNumber])
          )}
        </MainArea>
      </div>
      <div className="container mb-4">
        <QuestionBackButton courseId={courseId} />
      </div>
      <Footer />
    </div>
  );
};
