import { useCallback, useState } from "react";
import axiosInstance from "../configs/axios";
import { awsExports } from "../configs/aws-exports";

export const useLogging = () => {
  const [loggingLoading, setLoggingLoading] = useState(true);
  const [collectionHistoryId, setCollectionHistoryId] = useState(null);

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getCollectionHistoryId = useCallback(
    (userId, collectionId, courseId) => {
      setLoggingLoading(true);
      axiosInstance
        .post(
          "/logging/collection",
          {
            userId: userId,
            collectionId: collectionId,
            courseId: courseId,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          },
        )
        .then((res) => setCollectionHistoryId(res.data.collectionHistoryId))
        .catch((err) =>
          console.log("collectionHistoryIdの初期化に失敗しました: " + err),
        )
        .finally(() => setLoggingLoading(false));
      // eslint-disable-next-line
    },
    [accessToken, setCollectionHistoryId],
  );

  return {
    getCollectionHistoryId,
    collectionHistoryId,
    loggingLoading,
  };
};
