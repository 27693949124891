import React, { useEffect } from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

import { Header } from "../../components/base/Header";

import { ChoiceAnswer } from "../../components/question/choice/ChoiceAnswer";
import { SlideResult } from "../../components/slides/SlideResult";
import { DictationAnswer } from "../../components/question/dictation/DictationAnswer";
import { Footer } from "../../components/base/Footer";
import { MainArea } from "../../components/base/MainArea";
import { ProgressBar } from "../../components/parts/progresses/ProgressBar";
import { SubmitButton } from "../../components/parts/buttons/SubmitButton";
import { QuestionBackButton } from "../../components/parts/buttons/QuestionBackButton";
import ReactGA from "react-ga4";
import {useCommon} from "../../hooks/useCommon";
import {useUsers} from "../../hooks/useUsers";

export const QuestionAnswerPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // stateが存在しない場合のフォールバック処理
  useEffect(() => {
    if (!location.state) {
      // 適切なエラーページまたはホームページにリダイレクト
      navigate('/');
    }
  }, [location.state, navigate]);

  const {
    courseId,
    collectionId,
    collectionName,
    description,
    currentQuestionNumber,
    questionArray,
    answerArray,
    collectionHistoryId,
  } = location.state;

  const { userId } = useCommon();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const questionsNumber = {
    questionsLength: questionArray.length,
    questionNumber: currentQuestionNumber,
  };
  const getResultComponent = (question) => {
    if (question.screenType === "slide") {
      return <SlideResult />;
    }
    if (question.answerType === "choice") {
      return <ChoiceAnswer />;
    }
    if (question.answerType === "word" || question.answerType === "sentence") {
      return <DictationAnswer />;
    }
    return (
      <p>
        {"問題タイプが不正です"}Type: {question.answerType}
      </p>
    );
  };

  let toUrl;
  if (questionArray.length === currentQuestionNumber + 1) {
    toUrl = "/result";
  } else {
    toUrl = `/question?course_id=${courseId}&collection_id=${collectionId}`;
  }

  // const [isSlideNavigate, setIsSlideNavigate] = useState(false);

  if (questionArray[currentQuestionNumber].screenType === "slide") {
    return (
        <Navigate
            to={toUrl}
            state={{
              courseId: courseId,
              collectionId: collectionId,
              collectionName: collectionName,
              description: description,
              currentQuestionNumber: currentQuestionNumber + 1,
              answerArray: answerArray,
              questionArray: questionArray,
              collectionHistoryId: collectionHistoryId,
            }}
            replace
        />
    )
  }

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="main">
        <MainArea>
          <ProgressBar
            count={questionsNumber.questionNumber}
            totalCount={questionsNumber.questionsLength}
          />
          <>
            {getResultComponent(questionArray[currentQuestionNumber])}
            {questionArray.length === currentQuestionNumber + 1 ? (
              <div className="px-3 pb-3">
                <p className="my-3 text-xl">{"全ての問題が終了しました。"}</p>
                <div className="d-flex justify-content-center">
                  <SubmitButton route={toUrl} label={"結果を確認する"} />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center px-3 pb-3">
                <SubmitButton
                  route={toUrl}
                  label={"次の問題へすすむ"}
                  className="text-blue-700 font-semibold tracking-wide text-l hover:underline"
                  state={{
                    courseId: courseId,
                    collectionId: collectionId,
                    collectionName: collectionName,
                    description: description,
                    currentQuestionNumber: currentQuestionNumber + 1,
                    answerArray: answerArray,
                    questionArray: questionArray,
                    collectionHistoryId: collectionHistoryId,
                  }}
                />
              </div>
            )}
          </>
        </MainArea>
      </div>
      <div className="container mb-4">
        <QuestionBackButton courseId={courseId} />
      </div>
      <Footer />
    </div>
  );
};
